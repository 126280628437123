export async function offlineReco(user: string) {
    try {
        return fetch(`${process.env.PUBLIC_URL}/reco_food.json`).then(response => response.json()).then(data => {
            if (data[user]) {
                // console.log(data[user]);
                return data[user];
            } else {
                console.error('User not found in the recommendation list');
            }
        });
    } catch (error: any) {
        console.error('Error fetching recommendation:  ', error.message);
    }
}

export async function offlineUsers() {
    try {
        const response = await fetch(`${process.env.PUBLIC_URL}/reco_food.json`);
        const data = await response.json();
        // console.log(Object.keys(data));
        return Object.keys(data);

    } catch (error: any) {
        console.error('Error fetching users: ', error.message);
    }
}


export async function offlineExplanation(user: string, wni: string, type: string) {
    try {
        return fetch(`${process.env.PUBLIC_URL}/results_food.json`).then(response => response.json()).then(data => {
            // get explanation for user, wni and type from data json object
            let userNode = data[user];
            if (userNode) {
                let wniItem = userNode[wni];
                if (wniItem) {
                    let typeData = wniItem[type];
                    if (typeData) {
                        // console.log(typeData);
                        return typeData
                    } else {
                        console.error('Type not found');
                    }
                } else {
                    console.error('WNI not found');
                }
            } else {
                console.error('User not found');
            }
        })
    } catch (error: any) {
        // Handle the error
        console.error('Error fetching explanation:  ', error.message);
    }
}
