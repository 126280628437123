import {useRegisterEvents, useSigma} from "react-sigma-v2";
import {FC, useEffect} from "react";

function getMouseLayer() {
    return document.querySelector(".sigma-mouse");
}

const GraphEventsController: FC<{

    userNodes: string[],
    userValue: string | null,
    itemNodes: {} | undefined,
    itemValue: string | null,
    setHoveredNode: (node: string | null) => void,
    setUserValue: (node: string | null) => void,
    setItemValue: (node: string | null) => void,

}> = ({userNodes, itemNodes, userValue, setHoveredNode, setUserValue, setItemValue, children}) => {
    const sigma = useSigma();
    const graph = sigma.getGraph();
    const registerEvents = useRegisterEvents();

    /**
     * Initialize here settings that require to know the graph and/or the sigma
     * instance:
     */
    useEffect(() => {
        registerEvents({
            clickNode({node}) {


                var element: (HTMLSelectElement | null) = null;
                if (userNodes.includes(node)) {
                    element = document.getElementById("user") as HTMLSelectElement;
                    if (element)
                        element.value = node;
                    setUserValue(node);

                } else if (Object.keys(itemNodes || {})?.includes(node)) {
                    element = document.getElementById("WNI") as HTMLSelectElement;
                    if (element)
                        element.value = node;
                    setItemValue(node);

                }
            },
            doubleClickNode({node}) {


            },
            enterNode({node}) {
                setHoveredNode(node);
                // TODO: Find a better way to get the DOM mouse layer:
                const mouseLayer = getMouseLayer();
                if (mouseLayer) mouseLayer.classList.add("mouse-pointer");
            },
            leaveNode() {
                setHoveredNode(null);
                // TODO: Find a better way to get the DOM mouse layer:
                const mouseLayer = getMouseLayer();
                if (mouseLayer) mouseLayer.classList.remove("mouse-pointer");
            },
        });
    }, [itemNodes]);

    return <>{children}</>;
};

export default GraphEventsController;
