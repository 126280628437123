import {useLoadGraph} from "react-sigma-v2";
import {FC, useEffect} from "react";
import Graph from "graphology";
import Gradient from "javascript-color-gradient";


const GraphDataController: FC<{
        graph: Graph,
        sourceNode: string | null,
        WNI: string | null,
        mode: string | undefined,
        recoList: {} | any | undefined,
        explanations: [string, string][] | undefined,
    }> = ({graph, WNI, mode, sourceNode, recoList, explanations, children}) => {
        const loadGraph = useLoadGraph();

        function drawGraph() {


            if (recoList) {
                //style the recoList nodes
                var i = 0;
                var length = Object.keys(recoList || {}).length;
                if (length > 0) {


                    // console.log("recoList", Object.keys(recoList));

                    var r: [string, number][] = Object.keys(recoList).map(function (key) {
                        return [key, recoList[key]];
                    })

                    // Sort the array based on the second element
                    r.sort(function (first, second) {
                        return second[1] - first[1];
                    });

                    const gradientArray = new Gradient()
                        .setColorGradient("#e93e3a", "#f3903f", "#fff33b", "#5ad6ff", "#3d88ff")
                        .setMidpoint(length)
                        .getColors();
                    Object.entries(r).forEach(
                        ([index, [node, prr]]) => {

                            if (graph.hasNode(node)) {
                                graph.setNodeAttribute(node, "type", "image");
                                graph.setNodeAttribute(node, "image", `${process.env.PUBLIC_URL}/images/star-regular.svg`);
                                graph.setNodeAttribute(node, "size", "50");
                                graph.setNodeAttribute(node, "color", gradientArray[i]);
                            }
                            i++;
                        }
                    );


                    var topReco = r.at(0)![0];

                    graph.setNodeAttribute(topReco, "type", "image");
                    graph.setNodeAttribute(topReco, "image", `${process.env.PUBLIC_URL}/images/star-solid.svg`);
                    graph.setNodeAttribute(topReco, "size", "50");


                }


            }
            if (explanations) {
                // console.log("explanations", explanations);

                graph.forEachEdge((node) =>
                    graph.setEdgeAttribute(
                        node,
                        "zIndex", 0
                        ,
                    ),
                );


                // put the source node in the foreground
                if (sourceNode && graph.hasNode(sourceNode)) {

                    graph.setNodeAttribute(
                        sourceNode,
                        "zIndex", 1
                    )
                }

                Object.entries(explanations).forEach(
                    ([index, edge]) => {

                        var source = edge[0];
                        var target = edge[1];
                        if (!graph.hasEdge(source, target)) {
                            graph.addEdge(source, target)
                        }
                        graph.setEdgeAttribute(source, target, "type", "arrow");
                        graph.setEdgeAttribute(source, target, "size", "20");
                        graph.setEdgeAttribute(source, target, "label", "Why-Not Explanation");
                        graph.setEdgeAttribute(source, target, "forceLabel", true);
                        if (mode?.includes("add"))
                            graph.setEdgeAttribute(source, target, "color", "#ff8a00");
                        else if (mode?.includes("remove"))
                            graph.setEdgeAttribute(source, target, "color", "#9800ff");


                    }
                );
            }

            if (sourceNode && graph.hasNode(sourceNode)) {
                //style the source node
                graph.setNodeAttribute(sourceNode, "type", "image");
                graph.setNodeAttribute(sourceNode, "image", `${process.env.PUBLIC_URL}/images/person.svg`);
                graph.setNodeAttribute(sourceNode, "size", "50");
            }

            if (WNI && graph.hasNode(WNI)) {
                //style the WNI node
                graph.setNodeAttribute(WNI, "type", "image");
                graph.setNodeAttribute(WNI, "image", `${process.env.PUBLIC_URL}/images/circle-question-regular.svg`);
                graph.setNodeAttribute(WNI, "size", "50");
            }

        }

        /**
         * Feed graphology with the new dataset:
         */
        useEffect(() => {
            // console.log("graph", graph);
            if (!graph) return;
            drawGraph();
            loadGraph(graph);
            return () => graph.clear();
        }, [graph]);


        useEffect(() => {
            if (!graph) return;
            drawGraph();

            loadGraph(graph);
        }, [sourceNode, WNI, recoList, explanations]);
        return <>{children}</>;
    }
;

export default GraphDataController;
