import axios from 'axios';

const endpoint = process.env.REACT_APP_DEMO_API_ENDPOINT || `http://localhost:3000/api`;

export async function fetchReco(user: string, endpoint_local: string = endpoint): Promise<any> {
    try {
        const response = await axios.get(`${endpoint_local}/reco`, {
            headers: {"Access-Control-Allow-Origin": "*"},
            params: {user: user}
        });
        // console.log(response.data);
        return response.data;
        // Handle the response
    } catch (error: any) {
        // Handle the error
        console.error('Error fetching recommendation:  ', error.message);

    }

}

export async function fetchExplanation(user: string, wni: string, mode: string, endpoint_local: string = endpoint): Promise<any> {
    try {
        const response = await axios.get(`${endpoint_local}/expl`, {
            headers: {"Access-Control-Allow-Origin": "*"},
            params: {user: user, wni: wni, mode: mode}
        });
        // Handle the response

        return {
            type: response.data.type,
            source_node: response.data.source_node,
            why_not_item: response.data.why_not_item,
            check: response.data.check,
            explanation: response.data.explanation,
            better_option: response.data.better_option,
            top_item: response.data.top_item,
            k: response.data.k,
            time: response.data.time,
            new_k: response.data.new_k,
            ppr: response.data.ppr,

        };
    } catch
        (error: any) {

        console.error('Error fetching explanation:  ', error.message);
        // Handle the error
    }


}
