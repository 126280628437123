// src/App.tsx
import React, {useEffect, useState} from 'react';
import {BsChatRightText, BsHandThumbsUpFill} from "react-icons/bs";
import Panel from "./Panel";
import {requestAnswerEplanation} from "../types";
import Gradient from "javascript-color-gradient";
import Graph from "graphology";
import {Button, FloatingLabel, Form, Spinner} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import {offlineExplanation, offlineReco} from "../offlineware";


function convertToEnumeration(list: string[]): string {
    console.log("list", list);
    if (list.length === 0) {
        return "";
    } else if (list.length === 1) {
        return list[0];
    } else if (list.length === 2) {
        return `${list[0]} and ${list[1]}`;
    } else {
        const allButLast = list.slice(0, -1).join(", ");
        const last = list[list.length - 1];
        return `${allButLast}, and ${last}`;
    }
}


export const OfflineExplFrom: React.FC<{
    graph: Graph,
    userNodes: string[],
    itemNodes: {} | undefined,
    userValue: string | null,
    setUserValue: (node: string | null) => void,

    itemValue: string | null,
    setItemValue: (node: string | null) => void

    explanation: requestAnswerEplanation | undefined,
    setExplanation: (r: requestAnswerEplanation | undefined) => void
}> = ({graph, userNodes, itemNodes, userValue, setUserValue, itemValue, setItemValue, explanation, setExplanation}) => {
    const [formData, setFormData] = useState({user: '', WNI: '', mode: ''});
    const [results, setResults] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean | null>(null);

    const handleSubmit = async (event: React.FormEvent) => {
        setLoading(true)
        event.preventDefault();
        console.log(formData.user, formData.WNI, formData.mode)
        let requestAnswerExplanationPromise = await offlineExplanation(formData.user, formData.WNI, formData.mode);
        console.log(requestAnswerExplanationPromise);

        setExplanation(requestAnswerExplanationPromise!);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };
    const handleUserInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
        setUserValue(value);

    };
    const handleItemInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
        setItemValue(value);

    };

    useEffect(() => {
        formData.user = userValue || formData.user;
    }, [userValue, formData.user]);


    useEffect(() => {
        formData.WNI = itemValue || formData.WNI;
    }, [itemValue, formData.WNI]);

    useEffect(() => {
        formData.WNI = itemValue || formData.WNI || Object.keys(itemNodes || {"": ""})[1];
        formData.user = userValue || formData.user || userNodes[0];
        formData.mode = "add";

    }, []);

    useEffect(() => {
        setLoading(false);
    }, [explanation]);


    return (
        <Panel
            initiallyDeployed
            title={
                <>
                    <BsChatRightText className="text-muted"/> Why-Not Explanation
                </>
            }
        >
            <div>
                <p>
                    Select one item node from the list below (or double-click on a ☆ stared node) and validate.
                </p>
                <form className="explFrom" onSubmit={handleSubmit}>
                    <div>

                        <div style={{display: "none"}}>

                            <FloatingLabel label={"User:"}
                                           controlId="user">

                                <Form.Select size="sm"

                                             id="user"
                                             name="user"
                                             value={formData.user}
                                             onChange={handleUserInputChange}
                                             required
                                >
                                    {Array.from(userNodes, (key, index) => (
                                        <option key={key} value={key}>
                                            {graph.getNodeAttribute(key, "label") || key}
                                        </option>
                                    ))}
                                </Form.Select>
                            </FloatingLabel><br/>

                        </div>
                        <div>
                            <FloatingLabel
                                controlId="WNI" label="WNI (Why-Not Item):">

                                <Form.Select size="sm"

                                             id="WNI"
                                             name="WNI"
                                             value={formData.WNI}
                                             onChange={handleItemInputChange}
                                             required
                                >


                                    {itemNodes && Object.entries(itemNodes).map(([key, value], index) => {
                                        if (index > 0) {
                                            return <option key={graph.getNodeAttribute(key, "label") || key}
                                                           value={key}>
                                                {key}
                                            </option>
                                        }
                                    })}
                                </Form.Select>
                            </FloatingLabel><br/>

                        </div>
                        <div>
                            <FloatingLabel label={"Mode:"}
                                           controlId="mode">

                                <Form.Select size="sm"

                                             id="mode"
                                             name="mode"
                                             onChange={handleInputChange}

                                             value={formData.mode}
                                             required
                                >
                                    <option key="add" value="add">
                                        add
                                    </option>
                                    <option key="remove" value="remove">
                                        remove
                                    </option>
                                </Form.Select>
                            </FloatingLabel><br/>

                        </div>
                    </div>
                    {loading ?
                        <div className={"loading"}>
                            <Spinner animation="border" variant="primary"/>
                        </div>
                        :
                        <> <Button type="submit" variant="primary">Get Why-Not Explanation</Button>{' '} </>
                    }

                </form>

                <div id="results">

                    {explanation && <>
                        {explanation?.check ? (
                            <p> If <b> {graph.getNodeAttribute(explanation.source_node, "label")} </b>

                                {explanation.type?.includes("add") ? "had a connection to " : "removed the connection to "}
                                <b>{convertToEnumeration(explanation.explanation.map(([source, item]) => graph.getNodeAttribute(item, "label")))
                                } </b>
                                the recommendation would be
                                <b> {graph.getNodeAttribute(explanation.why_not_item, "label")}</b>.
                            </p>
                            //<pre>{JSON.stringify(results.explanation, null,)}</pre>
                        ) : <p> No explanation found for this situation</p>}
                    </>

                    }

                </div>
            </div>
        </Panel>
    );
};


export const OfflineRecoFrom: React.FC<{
        graph: Graph,
        userNodes: string[],
        userValue: string | null,
        setUserValue: (node: string | null) => void,
        recoList: {} | undefined,
        setRecoList: (node: {} | undefined) => void,

    }> = ({graph, userNodes, userValue, setUserValue, recoList, setRecoList}) => {
        const [formData, setFormData] = useState({
            user: ''
        });
        const [results, setResults] = useState<any | null>(null);
        const [resultsDisplay, setResultsDisplay] = useState<[string, number][] | null>(null);
        const [loading, setLoading] = useState<boolean | null>(null);

        const handleSubmit = async (event: React.FormEvent) => {
            event.preventDefault();
            setLoading(true)
            var requestAnswerRecoPromise = await offlineReco(formData.user)

            // console.log("requestAnswerRecoPromise", requestAnswerRecoPromise, formData.user);
            setRecoList(requestAnswerRecoPromise!)
            setResults(requestAnswerRecoPromise);


        };

        const handleUserInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            const {name, value} = event.target;
            setFormData({...formData, [name]: value});
            setUserValue(value);


        };
        const gradientArray = new Gradient()
            .setColorGradient("#e93e3a", "#f3903f", "#c2b92a", "#5ad6ff", "#3d88ff")
            .setMidpoint(Object.keys(results || {}).length)
            .getColors();

        useEffect(() => {
            formData.user = userValue || formData.user;
        }, [userValue, formData.user]);


        useEffect(() => {

            formData.user = userValue || formData.user || userNodes[0];

        }, []);

        useEffect(() => {
            setResults(recoList);
            setLoading(false);
        }, [recoList]);

        useEffect(() => {

        }, [loading]);


        useEffect(() => {

            if (results) {
                var r: [string, number][] = Object.keys(results).map(function (key) {
                    return [key, results[key]];
                })

                // Sort the array based on the second element
                r.sort(function (first, second) {
                    return second[1] - first[1];
                });


                // console.log("resultsDisplay", r);
                // console.log("results", results);
                setResultsDisplay(r)

            }


        }, [results]);
        return (
            <Panel
                initiallyDeployed
                title={
                    <>
                        <BsHandThumbsUpFill className="text-muted"/> Recommendation
                    </>
                }
            >
                <div>
                    <p>
                        Select one user node from the list below (or double-click on a <span
                        style={{color: "#0ca789"}}>⬤ User Node</span>) and
                        validate.
                    </p>
                    <form onSubmit={handleSubmit}>
                        <div>

                            <FloatingLabel label={"User:"}
                                           controlId="user">

                                <Form.Select size="sm"

                                             id="user"
                                             name="user"
                                             onChange={handleUserInputChange}
                                             value={userValue || formData.user}
                                             required
                                >
                                    {Array.from((userNodes || []), (key, index) => (
                                        <option key={key} value={key}>
                                            {graph.getNodeAttribute(key, "label") || key}
                                        </option>
                                    ))}
                                </Form.Select>
                            </FloatingLabel><br/>

                        </div>
                        {loading ?
                            <div className={"loading"}>
                                <Spinner animation="border" variant="primary"/>
                            </div>
                            :
                            <> <Button type="submit" variant="primary">Get Recommendations</Button>{' '} </>
                        }

                    </form>

                    <div id="results">

                        {results &&

                            <>
                                {resultsDisplay?.[0][0] && <p> The recommendation
                                    for {graph.getNodeAttribute(formData.user, "label")} is {graph.getNodeAttribute(resultsDisplay?.[0][0], "label")}  </p>
                                }
                                <Table striped bordered hover size="sm">
                                    <thead>
                                    <tr>
                                        <th>Item ID</th>
                                        <th>Item label</th>
                                        <th>PPR</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {// Create items array
                                        resultsDisplay?.map(
                                            ([key, value], index) => <tr key={key}>
                                                <td style={{color: gradientArray[index]}}>
                                                    {key}
                                                </td>
                                                <td style={{color: gradientArray[index]}}>
                                                    {index === 0 ? ("★") : ("☆")}
                                                    {graph.getNodeAttribute(key, "label") || key}
                                                </td>

                                                <td>{Number((value)?.toFixed(5))}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </>
                        }


                    </div>

                </div>
            </Panel>
        );
    }
;


