import React from "react";
import ReactDOM from "react-dom";

import "./styles.css";
import Root from "./views/Root";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import OfflineG from "./views/OfflineG";

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <div><Routes>
                <Route path="/" Component={Root}>
                </Route>
                <Route path="/offline" Component={OfflineG}>
                </Route>
            </Routes>
            </div>
        </Router>
    </React.StrictMode>,
    document.getElementById("root"),
);


