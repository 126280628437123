import React, {FC, useEffect, useState} from "react";
import {FullScreenControl, SigmaContainer, ZoomControl} from "react-sigma-v2";

import getNodeProgramImage from "sigma/rendering/webgl/programs/node.image";

import GraphSettingsController from "./GraphSettingsController";
import GraphEventsController from "./GraphEventsController";
import GraphDataController from "./GraphDataController";
import {requestAnswerEplanation} from "../types";
import drawLabel from "../canvas-utils";
import GraphTitle from "./GraphTitle";

import "react-sigma-v2/lib/react-sigma-v2.css";
import {GrClose} from "react-icons/gr";
import {BiBookContent, BiRadioCircleMarked} from "react-icons/bi";
import {BsArrowsFullscreen, BsEye, BsFullscreenExit, BsQuestionCircle, BsZoomIn, BsZoomOut} from "react-icons/bs";

import {parse} from "graphology-gexf/browser";
import Graph from "graphology";
import {ExplFrom, RecoFrom} from "./Forms";
import Panel from "./Panel";
import subgraph from "graphology-operators/subgraph";
import ExplGraphDataController from "./ExplGraphDataController";


const Root: FC = () => {
    const [showContents, setShowContents] = useState(false);
    const [dataReady, setDataReady] = useState(false);
    const [graph, setGraph] = useState<Graph | null>(null);
    const [explGraph, setExplGraph] = useState<Graph | null>(null);
    const [initialGraph, setInitGraph] = useState<string | null>(null);


    const [hoveredNode, setHoveredNode] = useState<string | null>(null);
    const [userValue, setUserValue] = useState<string | null>(null);
    const [itemValue, setItemValue] = useState<string | null>(null);
    const [request, setRequest] = useState<requestAnswerEplanation | undefined>(undefined);
    const [recoList, setRecoList] = useState<{} | undefined>(undefined);


    var userNodes = ["0", "1", "2", "3", "4", "5"];

    // Load data on mount:
    useEffect(() => {

        fetch(`${process.env.PUBLIC_URL}/toy.gexf`)
            .then((res) => res.text())
            .then((gexf) => {
                setInitGraph(gexf);
                setGraph(parse(Graph, gexf));
                requestAnimationFrame(() => setDataReady(true));
            });

    }, []);

    useEffect(() => {
        if (initialGraph)
            setGraph(parse(Graph, initialGraph));
        setRecoList(undefined);
        setItemValue(null);
        setRequest(undefined);
        requestAnimationFrame(() => setDataReady(true));
    }, [userValue]);

    useEffect(() => {
        if (initialGraph)
            setGraph(parse(Graph, initialGraph));
        setRequest(undefined);
        requestAnimationFrame(() => setDataReady(true));
    }, [itemValue]);


    useEffect(() => {
        if (initialGraph)
            setGraph(parse(Graph, initialGraph));
        requestAnimationFrame(() => setDataReady(true));
    }, [request]);


    useEffect(() => {

        if (graph) {
            if ((request && request.explanation.length >= 1)) {
                var explNodes: string[] = []
                explNodes.push(request?.source_node!)
                request?.explanation.map((e) =>
                    explNodes.push(e[1]))
                // console.log("explNodes", explNodes)
                setExplGraph(subgraph(graph!, explNodes))
            } else {
                setExplGraph(null)
                console.log("no explanations", request?.explanation.length)

            }
        }
    }, [request]);

    if (!graph) return null;

    return (
        <div id="app-root" className={showContents ? "show-contents" : ""}>
            <SigmaContainer id="main"
                            graphOptions={{type: "directed"}}
                            initialSettings={{
                                nodeProgramClasses: {image: getNodeProgramImage()},
                                labelRenderer: drawLabel,
                                defaultNodeType: "image",
                                defaultEdgeType: "arrow",
                                labelDensity: 0.07,
                                labelGridCellSize: 60,
                                labelRenderedSizeThreshold: 15,
                                labelFont: "Lato, sans-serif",
                                edgeLabelFont: "Lato, sans-serif",
                                edgeLabelSize: 20,
                                edgeLabelWeight: "bolder",
                                labelSize: 15,
                                zIndex: true,
                                renderEdgeLabels: true,
                            }}
                            className="react-sigma">
                <GraphSettingsController hoveredNode={hoveredNode} sourceNode={userValue} itemValue={itemValue}/>
                <GraphEventsController itemNodes={recoList} userNodes={userNodes}
                                       userValue={userValue} setUserValue={setUserValue}
                                       itemValue={itemValue} setItemValue={setItemValue}
                                       setHoveredNode={setHoveredNode}/>

                <GraphDataController graph={graph} explanations={request?.explanation} WNI={itemValue}
                                     mode={request?.type}
                                     recoList={recoList}
                                     sourceNode={userValue}/>


                {dataReady && (
                    <>
                        <div className="controls">
                            <div className="ico">
                                <button
                                    type="button"
                                    className="show-contents"
                                    onClick={() => setShowContents(true)}
                                    title="Show caption and description"
                                >
                                    <BiBookContent/>
                                </button>
                            </div>
                            <FullScreenControl
                                className="ico"
                                customEnterFullScreen={<BsArrowsFullscreen/>}
                                customExitFullScreen={<BsFullscreenExit/>}
                            />
                            <ZoomControl
                                className="ico"
                                customZoomIn={<BsZoomIn/>}
                                customZoomOut={<BsZoomOut/>}
                                customZoomCenter={<BiRadioCircleMarked/>}
                            />
                            <div className="legend">
                                <Panel initiallyDeployed={true} title={
                                    <>
                                        <BsQuestionCircle className="text-muted"/> Legend
                                    </>
                                }>

                                    <table className="table table-sm  ">
                                        <thead>
                                        <tr>
                                            <th>Node color</th>
                                            <th>Node type</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td style={{color: "#f24726"}}>⬤</td>
                                            <td style={{color: "#f24726"}}>Item Node</td>
                                        </tr>
                                        <tr>
                                            <td style={{color: "#0ca789"}}>⬤</td>
                                            <td style={{color: "#0ca789"}}>User Node</td>
                                        </tr>
                                        <tr>
                                            <td style={{color: "#4c55b6"}}>⬤</td>
                                            <td style={{color: "#4c55b6"}}>Category node</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </Panel>
                            </div>
                        </div>
                        <div className="contents">
                            <div className="ico">
                                <button
                                    type="button"
                                    className="ico hide-contents"
                                    onClick={() => setShowContents(false)}
                                    title="Show caption and description"
                                >
                                    <GrClose/>
                                </button>
                            </div>
                            <GraphTitle/>

                            <div className="panels">
                                {explGraph &&
                                    <Panel initiallyDeployed={true} title={
                                        <>
                                            <BsEye className="text-muted"/> Graph Explanation
                                        </>
                                    }>

                                        <div style={{height: "10em"}}>
                                            <SigmaContainer id={"explGraph"}
                                                            graphOptions={{type: "directed"}}
                                                            initialSettings={{
                                                                nodeProgramClasses: {image: getNodeProgramImage()},
                                                                labelRenderer: drawLabel,
                                                                defaultNodeType: "image",
                                                                defaultEdgeType: "arrow",
                                                                labelDensity: 0.07,
                                                                labelGridCellSize: 10,
                                                                labelRenderedSizeThreshold: 0,
                                                                labelFont: "Lato, sans-serif",
                                                                zIndex: true,
                                                            }}
                                                            className="react-sigma"
                                            >
                                                <ExplGraphDataController graph={explGraph}
                                                                         explanations={request?.explanation}
                                                                         mode={request?.type}
                                                                         sourceNode={userValue}/>

                                            </SigmaContainer>

                                        </div>
                                    </Panel>}
                                {recoList && <ExplFrom graph={graph} itemNodes={recoList} userNodes={userNodes}
                                                       userValue={userValue} setUserValue={setUserValue}
                                                       itemValue={itemValue} setItemValue={setItemValue}
                                                       explanation={request} setExplanation={setRequest}/>}
                                <RecoFrom graph={graph} userNodes={userNodes} userValue={userValue}
                                          setUserValue={setUserValue}
                                          setRecoList={setRecoList} recoList={recoList}/>

                            </div>

                        </div>
                    </>
                )}
            </SigmaContainer>
        </div>
    );
};

export default Root;
