import {useLoadGraph} from "react-sigma-v2";
import {FC, useEffect} from "react";
import Graph from "graphology";


const ExplGraphDataController: FC<{
        graph: Graph,
        sourceNode: string | null,
        mode: string | undefined,
        explanations: [string, string][] | undefined,
    }> = ({graph, mode, sourceNode, explanations, children}) => {
        const loadGraph = useLoadGraph();

        function drawGraph() {


            if (explanations) {

                graph.forEachNode((node) => {
                        graph.setNodeAttribute(node, "size", "10");
                    }
                );

                //put other nodes in the background
                // graph.forEachNode((node) =>{
                //      var myColor = Color('#222222')
                //         console.log("myColor", myColor.hex());
                //         graph.setNodeAttribute(node, "color", myColor.hex());
                //     }
                // );
                graph.forEachEdge((node) =>
                    graph.setEdgeAttribute(
                        node,
                        "zIndex", 0
                        ,
                    ),
                );


                // put the source node in the foreground
                if (sourceNode && graph.hasNode(sourceNode)) {

                    graph.setNodeAttribute(
                        sourceNode,
                        "zIndex", 1
                    )
                }

                Object.entries(explanations).forEach(
                    ([index, edge]) => {

                        var source = edge[0];
                        var target = edge[1];
                        if (!graph.hasEdge(edge[0], edge[1])) {
                            graph.addEdge(edge[0], edge[1])
                        }
                        graph.setEdgeAttribute(source, target, "type", "arrow");
                        graph.setEdgeAttribute(source, target, "size", "10");
                        graph.setEdgeAttribute(source, target, "zIndex", "1");
                        // console.log("mode", mode);
                        if (mode?.includes("add"))
                            graph.setEdgeAttribute(source, target, "color", "#ff8a00");
                        else if (mode?.includes("remove"))
                            graph.setEdgeAttribute(source, target, "color", "#9800ff");


                    }
                );
            }

            if (sourceNode && graph.hasNode(sourceNode)) {
                //style the source node
                graph.setNodeAttribute(sourceNode, "type", "image");
                graph.setNodeAttribute(sourceNode, "image", `${process.env.PUBLIC_URL}/images/person.svg`);
                graph.setNodeAttribute(sourceNode, "size", "10");
            }


        }

        /**
         * Feed graphology with the new dataset:
         */
        useEffect(() => {

            if (!graph) return;
            drawGraph();
            loadGraph(graph);
            return () => graph.clear();
        }, [graph]);


        return <>{children}</>;
    }
;

export default ExplGraphDataController;
